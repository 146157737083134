import {
    HomeDemo1IcoPlatforms1,
    HomeDemo1IcoPlatforms2,
    HomeDemo1IcoPlatforms3,
    HomeDemo1IcoPlatforms4,
    HomeDemo1IcoPlatforms5,
    HomeDemo1IcoPlatforms6,

    HomeDemo1Services1,
    HomeDemo1Services2,
    HomeDemo1Services3,
    HomeDemo1Services4,
    HomeDemo1Services5,
    HomeDemo1Services6,

    // HomeDemo1TokenIcon1,

    HomeDemo1TeamImg1,
    HomeDemo1TeamImg2,
    HomeDemo1TeamImg3,
    HomeDemo1TeamImg4,

    HomeDemo1Partners1,
    HomeDemo1Partners2,
    HomeDemo1Partners3,
    HomeDemo1Partners4,
    HomeDemo1Partners5,
    HomeDemo1Partners6,
    HomeDemo1Partners7,
    HomeDemo1Partners8,
    HomeDemo1Partners9,
    HomeDemo1Partners10,
    HomeDemo1Partners11,
    HomeDemo1Partners12,

    HomeDemo1BlogImg1,
    HomeDemo1BlogImg2,
    HomeDemo1BlogImg3,

    HomeDemo3RoadmapIcons1,
    HomeDemo3RoadmapIcons2,
    HomeDemo3RoadmapIcons3,
    HomeDemo3RoadmapIcons4,
    HomeDemo3RoadmapIcons5,
    HomeDemo3RoadmapIcons6,
    HomeDemo3RoadmapIcons7,
    HomeDemo3RoadmapIcons8,
    HomeDemo3RoadmapIcons9,

} from '../../utils/allImgs'

export const VerticalSocial = [
    { nameIco: 'fa fa-telegram' },
    { nameIco: 'fa fa-medium' },
    { nameIco: 'fa fa-facebook' },
    { nameIco: 'fa fa-twitter' },
    { nameIco: 'fa fa-github' },
    { nameIco: 'fa fa-instagram' },
    { nameIco: 'fa fa-linkedin' }
]

export const SingleCoolFact = [
    {
        img: HomeDemo1IcoPlatforms1,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms2,
        ico_check: false
    },
    {
        img: HomeDemo1IcoPlatforms3,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms4,
        ico_check: true
    },
    {
        img: HomeDemo1IcoPlatforms5,
        ico_check: false
    },
    {
        img: HomeDemo1IcoPlatforms6,
        ico_check: true
    }
]

export const service_single_content = [
    {
        img: HomeDemo1Services1,
        title: 'Fast transaction speed',
        content: 'TON Blockchain set a world record by achieving 104,715 TPS audited by Certik'
    },
    {
        img: HomeDemo1Services2,
        title: 'High scalability',
        content: 'Transactions are processed quickly, providing a smooth user experience'
    },
    {
        img: HomeDemo1Services3,
        title: 'Low transaction fees',
        content: 'Even if TON price increases 100 times, transactions will remain ultra-cheap'
    },
    {
        img: HomeDemo1Services4,
        title: 'Security',
        content: 'Security of the TON blockchain, ensuring the safety of user funds and assets'
    },
    {
        img: HomeDemo1Services5,
        title: 'AI market analysis',
        content: 'Predict price trends and provide sound investment recommendations to users'
    },
    {
        img: HomeDemo1Services6,
        title: 'AI fraud detection',
        content: 'Identify and prevent fraudulent activities and market manipulation on the DEX'
    },
]

export const timelineInfo = [
    {
        left: "10%",
        ClassName: "data_cir cir_0",
        date: "Q1 2024",
        IsSpan: true,
        TextSpan: "Release of White Paper and Airdrop campaign.",
        IsTowLi: false,
        TextTowLi1: "",
        TextTowLi2: "",
        IsThreeLi: false,
        TextThreeLi1: "Release of White Paper and Airdrop campaign.",
        TextThreeLi2: "Marketing & communication campaigns",
        TextThreeLi3: "Launch Tonfi social media channels, swap & airdrop campaign",
        IsFourLi: false,
        TextFourLi1: "Whitepaper release",
        TextFourLi2: "",
        TextFourLi3: "",
        img: HomeDemo3RoadmapIcons1

    },
    {
        left: "20%",
        ClassName: "data_cir cir_1",
        date: "Q2 2024",
        IsSpan: true,
        TextSpan: "Launching the TONFI Mining Platform and issuing TONFI Shares with an initial value of $0.1.",
        IsTowLi: false,
        TextTowLi1: "Launch Tonfi minting platform",
        TextTowLi2: "Launch Tonfi stock issueance with initial price of $0.1",
        IsThreeLi: false,
        TextThreeLi1: "",
        TextThreeLi2: "",
        TextThreeLi3: "",
        IsFourLi: false,
        TextFourLi1: "built our team",
        TextFourLi2: "company establishment",
        TextFourLi3: "legal issues setting",
        TextFourLi4: " concept development",
        img: HomeDemo3RoadmapIcons2

    },
    {
        left: "30%",
        ClassName: "data_cir cir_2",
        date: "Q3 2024",
        IsSpan: true,
        TextSpan: "Launch of Airdrop Tap To Earn platform (Telegram)",
        IsTowLi: false,
        TextTowLi1: 'Signing of exclusive distribution partnership with Ukiss cold wallet for the Asian region',
        TextTowLi2: 'Launch Tonfi dex, crosschain, launchpad',
        IsThreeLi: false,
        TextThreeLi1: "",
        TextThreeLi2: "",
        TextThreeLi3: "",
        IsFourLi: false,
        TextFourLi1: "",
        TextFourLi2: "",
        TextFourLi3: "",
        TextFourLi4: "",
        img: HomeDemo3RoadmapIcons3

    },
    {
        left: "40%",
        ClassName: "data_cir cir_3",
        date: "Q4 2024",
        IsSpan: false,
        TextSpan: "",
        IsTowLi: false,
        TextTowLi1: "Launch Tonfi Dex on Google Play",
        TextTowLi2: "Launch Tonfi Dex on Apple Store",
        IsThreeLi: false,
        TextThreeLi1: "website launch",
        TextThreeLi2: "White Paper published",
        TextThreeLi3: "Private and Pre-sale start",
        IsFourLi: true,
        TextFourLi1: "Launching decentralized exchange platform STONFI.Ai DEX",
        TextFourLi2: "Mint Contract Token",
        TextFourLi3: "Tonfi GPT AI",
        TextFourLi4: "Swap Tonchain, Bsc",
        img: HomeDemo3RoadmapIcons4

    },
    {
        left: "50%",
        ClassName: "data_cir cir_4 red_c",
        date: "Q1-Q2 2025",
        IsSpan: false,
        TextSpan: "Launch Tonfiscan Blockchain platform. - Add Pool Tonchain",
        IsTowLi: true,
        TextTowLi1: "Launch Tonfiscan Blockchain platform. - Add Pool Tonchain",
        TextTowLi2: "Launching the Tonfi Bridge platform linking BSC and ETH networks with Ton",
        IsThreeLi: false,
        TextThreeLi1: "Listing on XT, LBANK, MEXC",
        TextThreeLi2: "Listing on Bybit",
        TextThreeLi3: "Listing price $0.5",
        IsFourLi: false,
        TextFourLi1: "",
        TextFourLi2: "",
        TextFourLi3: "",
        TextFourLi4: "",
        img:  HomeDemo3RoadmapIcons5

    },
    {
        left: "60%",
        ClassName: "data_cir cir_6 next",
        date: "Q3-Q4 2025",
        IsSpan: false,
        TextSpan: "",
        IsTowLi: true,
        TextTowLi1: "Launching Stake platform, Stake pairs with APY attract users!!!",
        TextTowLi2: "Listed on DEX Tonchain #Tonfi.",
        IsThreeLi: false,
        TextThreeLi1: "Launch own blockchain",
        TextThreeLi2: "NFT Collection",
        TextThreeLi3: "new partnerships and new marketplace support",
        IsFourLi: false,
        TextFourLi1: "Launch own blockchain",
        TextFourLi2: "NFT Collection",
        TextFourLi3: "Listing 3 CEXs and DEXs price $2",
        TextFourLi4: "Mini projects collaboration with ecosystem",
        img: HomeDemo3RoadmapIcons7

    },
    {
        left: "70%",
        ClassName: "data_cir cir_7 next",
        date: "Q1-Q2 2026",
        IsSpan: false,
        TextSpan: "",
        IsTowLi: true,
        TextTowLi1: "Launching Pool Liquidity Tonfichain platform.",
        TextTowLi2: "List 10 CEX Tier1 exchanges #Tonfi",
        IsThreeLi: false,
        TextThreeLi1: "Mainnet launch",
        TextThreeLi2: "Wallet beta-version release",
        TextThreeLi3: "Masternodes implementation",
        IsFourLi: false,
        TextFourLi1: "",
        TextFourLi2: "",
        TextFourLi3: "",
        TextFourLi4: "",
        img: HomeDemo3RoadmapIcons8

    },
    {
        left: "80%",
        ClassName: "data_cir cir_8 next",
        date: "Q3-Q4 2026",
        IsSpan: false,
        TextSpan: "",
        IsTowLi: false,
        TextTowLi1: "Platform integration to electronic marketplaces",
        TextTowLi2: "Zero Fee",
        IsThreeLi: false,
        TextThreeLi1: "",
        TextThreeLi2: "",
        TextThreeLi3: "",
        IsFourLi: true,
        TextFourLi1: "Launching TONFI NFT collection",
        TextFourLi2: "Game NFT, Market Place NFT TON",
        TextFourLi3: "AI image generation and personalized Mint NFT for users' collections...",
        TextFourLi4: "Expand listing to more CEX exchanges #Tonfi.",
        img: HomeDemo3RoadmapIcons9
    },
    {
        left: "90%",
        ClassName: "data_cir cir_8 next",
        date: "Q1-Q2 2027",
        IsSpan: false,
        TextSpan: "",
        IsTowLi: true,
        TextTowLi1: "Launching TONFI Social, a Web3 social media platform. (A place to exchange crypto news, Tonchain community, KOLs publish for communication activities...)",
        TextTowLi2: "More CEX Tier 2, Tier 3",
        IsThreeLi: false,
        TextThreeLi1: "",
        TextThreeLi2: "",
        TextThreeLi3: "",
        IsFourLi: false,
        TextFourLi1: "Launching TONFI NFT collection",
        TextFourLi2: "Game NFT, Market Place NFT TON",
        TextFourLi3: "Platform AI picture drawing application and personalized Mint NFT for users' collections...",
        TextFourLi4: "Expand listing to more CEX exchanges #Tonfi.",
        img: HomeDemo3RoadmapIcons9

    }
]

export const ServiceBlock = [
    {
        classIco: "icon ti-mobile",
        title: "AI-Powered Strategies",
        content:"Leverage advanced AI algorithms that analyze market trends and optimize your crypto investments for smarter, data-driven decisions."
    },
    {
        classIco: "icon ti-widget",
        title: "Seamless DeFi Integration",
        content:"Our DApp seamlessly integrates with popular DeFi protocols, allowing you to manage assets, stake tokens, and earn rewards with ease."
    },
    {
        classIco: "icon ti-settings",
        title: "Enhanced Security",
        content:"Built on blockchain technology, our DApp ensures top-tier security for your assets, with AI-driven risk management features for added protection."
    },
]

export const SocialListIco = [
    { icoName: "fa fa-facebook-square" },
    { icoName: "fa fa-twitter-square" },
    { icoName: "fa fa-github-square" },
    { icoName: "fa fa-instagram" },
    { icoName: "fa fa-medium" }
]

export const FQAInfo = [
    {
        text: 'What is StonFi AI?',
        ID: 'Q1',
        content: 'StonFi AI is a platform that combines artificial intelligence (AI) with decentralized finance (DeFi) to provide advanced cryptocurrency solutions. We leverage AI-powered strategies to help users optimize their DeFi investments and take advantage of cutting-edge opportunities in the crypto space.'
    },
    {
        text: 'How does AI improve DeFi strategies?',
        ID: 'Q2',
        content: 'Our AI algorithms analyze vast amounts of data in real time to identify market trends, optimize investment strategies, and reduce risk. By incorporating AI, StonFi AI provides users with data-driven insights that enhance decision-making and improve financial outcomes in the DeFi ecosystem.'
    },
    {
        text: 'Who can benefit from StonFi AI’s solutions?',
        ID: 'Q3',
        content: 'Whether you’re an experienced crypto investor or new to decentralized finance, StonFi AI offers tools that cater to various levels of expertise. Our solutions are designed to help users make smarter, more informed decisions in the fast-evolving world of cryptocurrency and DeFi.'
    },
    {
        text: 'Is StonFi AI secure?',
        ID: 'Q4',
        content: 'Security is a top priority at StonFi AI. We implement robust blockchain-based protocols and AI-driven risk assessments to ensure your investments are safe. Additionally, we follow best practices for DeFi security to protect users from potential threats and vulnerabilities.'
    },
]

export const DocElementTitle = [
    {
        title: 'WhitePaper'
    },
    {
        title: 'OnePager'
    },
    {
        title: 'Rating Review'
    },
    {
        title: 'Marketing Plan'
    },
]

export const TokenText = [
    {
        text: 'Total Supply: 1,000,000,000 (Tonfi)',
    },
    {
        text: 'Chain Ton (Tonfi)',
    },
    {
        text: 'Burn : 200,000,000 Tonfi (20%)',
    },
    {
        text: 'Listing & liquidity : 100,000,000 Tonfi (10%)',
    },
    {
        text: 'Advisor : 50,000,000 Tonfi (5%)',
    },
    {
        text: 'Ecosystem : 500,000,000 Tonfi (50%)',
    },
    {
        text: 'Marketing, Airdrop : 50,000,000 Tonfi (5%)',
    },
    {
        text: 'Team/ Dev : 100,000,000 Tonfi (10%)',
    },
]

export const MissionData = [
    {
        icoName: "ti-shine",
        title: "Quality"
    },
    {
        icoName: "ti-ruler-pencil",
        title: "Creativity"
    },
    {
        icoName: "ti-heart",
        title: "Reliability"
    },
]

export const TeamMember = [
    {
        img: HomeDemo1TeamImg1,
        title: 'Sunny khan',
        text: 'Executive Officer'
    },
    {
        img: HomeDemo1TeamImg2,
        title: 'Ajoy Das',
        text: 'Business Development'
    },
    {
        img: HomeDemo1TeamImg3,
        title: 'Afroza Mou',
        text: 'UX/UI Designer'
    },
    {
        img: HomeDemo1TeamImg4,
        title: 'Lim Sarah',
        text: 'Head of Marketing'
    },
    {
        img: HomeDemo1TeamImg1,
        title: 'Sunny khan',
        text: 'Executive Officer'
    },
    {
        img: HomeDemo1TeamImg2,
        title: 'Ajoy Das',
        text: 'Business Development'
    },
    {
        img: HomeDemo1TeamImg3,
        title: 'Afroza Mou',
        text: 'UX/UI Designer'
    },
    {
        img: HomeDemo1TeamImg4,
        title: 'Lim Sarah',
        text: 'Head of Marketing'
    },
]

export const PartnersData = [
    { img: HomeDemo1Partners1 },
    { img: HomeDemo1Partners2 },
    { img: HomeDemo1Partners3 },
    { img: HomeDemo1Partners4 },
    { img: HomeDemo1Partners5 },
    { img: HomeDemo1Partners6 },
    { img: HomeDemo1Partners7 },
    { img: HomeDemo1Partners8 },
    { img: HomeDemo1Partners9 },
    { img: HomeDemo1Partners10 },
    { img: HomeDemo1Partners11 },
    { img: HomeDemo1Partners12 }
]

export const BlogPost = [
    { img: HomeDemo1BlogImg1 },
    { img: HomeDemo1BlogImg2 },
    { img: HomeDemo1BlogImg3 }
]
